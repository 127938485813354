<template>
    <VuePureLightbox class="lightbox">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <section class="login has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header">
                    <p class="org-description is-size-5">Login:</p>
                </td>
            </tr>
            <tr>
                <td class="form-content">
                    <form>
                    <ValidationProvider rules="required|email" name="Email" v-slot="{ errors, valid }">
                        <b-field label="Email Address" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-input type="email" maxlength="50" onkeyup='this.value = this.value.toLowerCase();' v-model="user.email">
                            </b-input>
                        </b-field>
                    </ValidationProvider>
                    <ValidationProvider rules="required|min:8" vid="password" name="Password" v-slot="{ errors, valid }">
                        <b-field label="Password" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-input type="password" v-model="user.password" password-reveal v-on:keyup.native.enter="handleSubmit(login)">
                            </b-input>
                        </b-field>
                    </ValidationProvider>
                    </form> 
                    <p class="org-description is-size-7"><router-link :to="{ name: 'SendReset' }">Forgot your password?</router-link></p>
                    <button class="button submit is-primary is-outlined" @click="handleSubmit(login)">
                        <b-icon pack="fa" icon="user"></b-icon>
                        <span>Login</span>
                    </button> 
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <div class="form-group">
                        <div v-if="message" class="error is-size-7" role="alert">{{message}}</div>
                    </div>  
                </td>
            </tr>
            </table>
        </div>
    </section>
    </ValidationObserver>
    </VuePureLightbox>
</template>

<script>
import User from '../models/user';
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: 'Login',
  props: {
      next: {
          type: String,
          default: "home"
      },
  },
  components: {
            ValidationObserver,
            ValidationProvider,
  },
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
      gsk: process.env.VUE_APP_GOOGLE_SITE_KEY,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      console.log(this.next);
      this.$router.push('/dashboard/' + this.next);
    }
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded().then(() => {
          console.log('recaptcha loaded')
      });

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login').then((token) => {
          console.log(token) // Will print the token
      });

      // Do stuff with the received token.
    },
    login() {
        requestAnimationFrame(() => {
            this.user.api = process.env.VUE_APP_API;
            console.log("Attempting to Login for: " + this.user.email + " via " + this.user.api)
            this.loading = true;

            if (this.user.email && this.user.password && this.user.api) {
                this.$store.dispatch('auth/login', this.user).then(
                (response) => {
                        console.log(response.status);
                        if (response.status == true) {
                            this.message = "Success!";
                            this.$router.push('/dashboard/' + this.next);
                        } else {
                            this.message = "Invalid Credentials...";
                            this.user.password="";
                        }
                    },
                    error => {
                        this.loading = false;
                        this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                    }
                );
            }
        });
    }
  }
};
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .login {
        padding-top: 0px; // 55px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .submit {
        margin-top: 30px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        min-width: 450px;
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px; // 40px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 10%;
        padding-right: 10%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
    }
    .error {
        padding-top: 10px;
        color: red;
    }
</style>